import $ from "cash-dom";
import UIkit from 'uikit';
import { getCookie, setCookie } from "./cookies.js";

import "./api.js";
import "./common.js";
import "./icons.js";

window.$ = $;
window.UIkit = UIkit;
// TODO: remove in prod. Useful for console

$(function () {
    // OPEN WEBSOCKET
    window.API.getUserSocket();

    // RECAPTCHA RESPONSIVE FIX METHOD
    function resizeReCaptcha() {
        if($('.g-recaptcha').length > 0){
            var width = $('.g-recaptcha').parent().width();
            var scale = 1;
            if (width < 302) {
                scale = width / 302;
            }
            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
            // $('.g-recaptcha').addClass("uk-flex uk-flex-center");
        }
    }

    // RECAPTCHA RESPONSIVE FIX
    resizeReCaptcha();
    $(window).on('resize', function(){
       resizeReCaptcha();
    });

    // MODAL
    $("body").on("click", ".uk-modallink", function (evt) {
        evt.preventDefault();
        const type = $(this).data("modallinktype");
        const url = $(this).attr("href");
        if (type === "chat") window.API.openChat(url);
        else if (type === "video") window.API.openVideo(url);
        else if (type === "pdf") window.API.openPdf(url);
        else if (type === "image") window.API.openImage(url);
        else if (type === "link")window.API.openLink(url);
        else if (type === "download") window.API.downloadFile(url);
        else if (type === "event") window.API.openevent(url);
        else if (type === "audio") window.API.openAudio(url);
        else window.API.openPage(url);
    });
    $("*[uk-modal]").on("hidden", function(event){
        $(this).html("");
    });

    //COOKIES
    if (!getCookie("cookies_id")) {
        const url = $('#cookieDiv').data("url");
        fetch(url)
        .then(response => response.text())
        .then(html => {
            $('#cookieDiv').html(html);
        }).catch(error => console.log("Error fetching cookies form" + url));
        $('#cookieDiv').show();
    }
    $("body").on("submit", "#cookieForm", function(e){
        e.preventDefault();
        $(this).postFormAsAjax().then(data => {
            setCookie("cookies_id", data["id"], 365);
            setCookie("cookies_needed", data["needed"], 365);
            setCookie("cookies_statistic", data["statistic"], 365);
            setCookie("cookies_marketing", data["marketing"], 365);
            $("#cookieDiv").hide();
            var _paq = window._paq = window._paq || [];
            console.log(decodeURIComponent(document.cookie).indexOf("cookies_statistic=true"));
            if (decodeURIComponent(document.cookie).indexOf("cookies_statistic=true") > 0){
                _paq.push(['setConsentGiven']);
            }
            _paq.push(['trackPageView']);
        }).catch(error => console.log("Error saving cookies preferences " + error));
    });

    // SHOW HIDE PASSWORD
    $(".showhidepassword ").on("click", function (evt) {
        const input = $(this).siblings("input")[0];
        if (input.type === "password") {
            $(this).attr("data-uk-icon", "icon: eye;");
            input.type = "text";
        }
        else {
            $(this).attr("data-uk-icon", "icon: eye-off");
            input.type = "password"
        }
        evt.preventDefault();
    });


    // FIGURES COUNTER METHOD
    function timedCounter(finalValue, duration, element){
        const startTime = (new Date).getTime();
        (function update(){
            const currentTime = (new Date).getTime();
            const value = Math.floor(finalValue*(currentTime - startTime)/duration);
            UIkit.util.html(element, value.toString());
            if(value >= finalValue.toString())
                UIkit.util.html(element, finalValue);
            else
                setTimeout(update, 100);
        })();
    }

    // FIGURES COUNTER
    UIkit.util.each(UIkit.util.$$(".uk-counters"), function(counters){
        let index = 0;
        UIkit.scrollspy(counters, {repeat: false, delay: 100});
        UIkit.util.on(counters, 'inview', function (){
            UIkit.util.each(UIkit.util.$$(".uk-counter", counters), function(counter){
                index += 1;
                const number = UIkit.util.$(".number", counter);
                UIkit.util.html(number, "0");
                const target = UIkit.util.data(number, 'target');
                setTimeout(function(){
                    timedCounter(target, 3000, number)
                }, index * 500);
            });
        });
    });

    // VIDEO PLAY BUTTON
    // $(".playable-video").each(function(event){
    //   const video = $("video", this);
    //   const button = $(".play-button", this);
    //   const overlay = $(".uk-overlay", this);
    //   button.on("click", function(event){
    //     overlay.hide();
    //     video[0].play();
    //   });
    //   video.on("click", function(event){
    //     // if is playing
    //     if (video[0].currentTime > 0 && !video[0].paused && !video[0].ended && video[0].readyState > 2){
    //       video[0].pause();
    //       overlay.show();
    //     }
    //   });
    // });

    // MESSAGES
    $(".messages .uk-alert").each(function(){
        const message = $(this);
        let TIMEOUT = 7000;
        console.log("setting timeout");
        setTimeout(function(){
            console.log("removing message");
            message.addClass("uk-animation-fade").addClass("uk-animation-reverse");
            setTimeout(function(){
                message.remove();
            }, 1000);
        }, TIMEOUT);
        TIMEOUT += 1000;
    });

    // SUSCRIPTION SPECIFIC
    $(".uk-radio-wrapper>div.uk-radio").removeClass("uk-radio").addClass("uk-form-controls");
});
