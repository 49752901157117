import $ from "cash-dom";
import TomSelect from "./tom-select.js";
import flatpickr from "flatpickr";


const TOMSELECT_MULTIPLE_PLUGINS = {
    "remove_button": {},
    "clear_button": {className: "uk-text-secondary uk-remove-border",
        html: (data) => {
            return `<a class="${data.className}" title="${data.title}" data-uk-icon="icon:x; ratio:1.25"></a>`;
        }
    }
};

function initTomSelect($select){
    const multiple = $select.attr("multiple");
    let plugins = {};
    if (multiple) plugins = TOMSELECT_MULTIPLE_PLUGINS;
    let tomSelect = new TomSelect($select[0], {
        labelField: "title",
        valueField: "pk",
        plugins: plugins,
    });
    return tomSelect;
}

function initAfterAjax(){
    // Stop bubbling to avoid modal close on tab switching inside modal
    $('#modal-container .uk-switcher > li').on("hidden", function (e) {
        e.stopPropagation();
    });
    //initUploder();

    // Select e multiselect
    $("select").each(function() {
        const $select = $(this);
        initTomSelect($select);
        $select.next(".ts-wrapper").removeClass("uk-select");

        const dependsOn = $select.data("depends-on");
        if (dependsOn) {
            $(dependsOn).on("change", function() {
                if($(this).val()){
                    const url = $select.data("depends-on-url").replace("888888", $(this).val());
                    fetch(url).then(response => response.json()).then(data => {
                        tomSelect.destroy();
                        const tomSelect = initTomSelect($select);
                        for (const selectValue in tomSelect.options){
                            let found = false;
                            for (const value of data.result){
                                if (String(value.pk) === selectValue){
                                    found = true;
                                    break;
                                }
                            }
                            if (!found){
                                tomSelect.removeOption(selectValue);
                            }
                        }
                    });
                }
                else{
                    tomSelect.clear();
                    tomSelect.clearOptions();

                }
                tomSelect.refreshOptions();
            });
            $(dependsOn).trigger("change");
        }
    });
    //tinymce
    initTiny();

    // datetimepicker
    $(".uk-datetime").each(function() {
        flatpickr($(this), {dateFormat: "Y-m-d H:i",
                            enableTime: true,
                            time_24hr: true,
                            plugins: [new confirmDatePlugin({confirmText: "OK "})],
        });
    });
}

function initTiny(){
    $("textarea.tinymce").each(function() {
        const $textarea = $(this);
        if (!$textarea.hasClass("tiny-initialized")){
            const defaultParams = {
                target: $textarea[0],
                convert_urls: false,
                language: $("html").attr("lang"),
            };
            const elementParams = $textarea.data("mce-conf");
            tinymce.init({...defaultParams,...elementParams});
            $(this).addClass("tiny-initialized");
        }
    });
}


function htmlReplace(data){
    const url = data["url"] || ".*";
    if (window.location.href.match(url)){
        const html = data["html"] || "";
        const target = data["target"] || "body";
        $(target).html(html);
    }
}

function pageRedirect(data){
    const from_url = data["from_url"] || ".*";
    if (window.location.href.match(from_url)){
        window.location.href = data["to_url"];
    }
}

function executeAction(message, type) {
    if (!type) type = message["type"];
    if (type == "actions.list"){
        message["actions"].forEach(action => {
            executeAction(action);
        });
    }
    else if (type == "page.redirect"){
        pageRedirect(message);
    }
    else if (type == "html.replace") {
        htmlReplace(message);
        initAfterAjax();
    }
    else {
        console.log("Error executing action " + message);
    }
}

export {executeAction, initAfterAjax, initTiny}
